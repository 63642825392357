<template>
  <div id="view-site" class="pt-0 pt-md-4 pb-3 px-0 px-md-5">
    <div class="site-header">
      <template v-if="site">
        <img
          v-if="site.header_image"
          :src="Helper.formatMediaUrl(site.header_image)"
        />
        <div class="site-name">
          <h1>{{ site.name }}</h1>
          <h2>
            {{
              Helper.formatAddress(
                null,
                site.city,
                site.state ? site.state.name : null
              )
            }}
          </h2>
        </div>
      </template>
      <div class="site-map-container">
        <div id="map" v-if="site"></div>
      </div>
      <div
        class="site-mobile-menu d-flex d-md-none w-100 justify-content-between align-items-center"
      >
        <div class="btn-group">
          <button
            type="button"
            class="btn dropdown-toggle no-caret dropdown-toggle-split"
            data-toggle="dropdown"
            data-offset="10 -20"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span>
              <i class="fas fa-bars"></i>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <p class="dropdown-item mb-0 font-weight-medium">Site Menu</p>
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              :class="{ active: $route.name == `site.${link.section}` }"
              href="#"
              v-for="link in links"
              :key="link.section"
              @click.prevent="goToSection(link.section)"
            >
              {{ link.displayName }}
            </a>
            <a
              v-if="site && site.azimuth_url"
              class="dropdown-item"
              :href="site && site.azimuth_url"
              target="_blank"
            >
              Azimuth
            </a>
          </div>
        </div>
        <p class="mb-0 font-weight-bold px-4 text-uppercase">
          {{
            links.find((link) => `site.${link.section}` == $route.name)
              .displayName
          }}
        </p>
      </div>
      <div class="site-tabs">
        <a
          v-for="link in links"
          :key="link.section"
          href="#"
          @click.prevent="goToSection(link.section)"
          :class="{ active: $route.name == `site.${link.section}` }"
          class="d-none d-md-flex"
        >
          {{ link.displayName }}
        </a>
        <a
          v-if="site && site.azimuth_url"
          :href="site && site.azimuth_url"
          class="d-none d-md-flex"
          target="_blank"
        >
          Azimuth
        </a>
      </div>
    </div>
    <router-view
      @toggleSpinner="toggleSpinner"
      :site="site"
      @updated="updated"
    />
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";

export default {
  name: "site-view",
  data() {
    return {
      site: null,
      map: null,
      links: [
        { section: "overview", displayName: "Overview" },
        { section: "sharer", displayName: "Sharer" },
        { section: "interactive-tour", displayName: "Interactive Tour" },
        { section: "3d-visualisation", displayName: "3D Visualisation" },
        { section: "audit", displayName: "Audit" },
      ],
    };
  },
  watch: {
    "$route.params": {
      handler() {
        if (this.$route.params.id) {
          if (this.$route.name == "site") {
            this.$router.replace({
              name: "site.overview",
              params: {
                id: this.$route.params.id,
              },
            });
          } else if (!this.site || this.site._id != this.$route.params.id) {
            this.getSite();
          }
        } else {
          this.$router.replace({
            name: "dashboard",
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updated(type, result) {
      if (type == "site") {
        this.site = result;
      }
    },
    goToSection(section) {
      if (`site.${section}` !== this.$route.name) {
        this.$router.replace({
          name: `site.${section}`,
          params: {
            id: this.$route.params.id,
          },
        });
      }
    },
    initMap() {
      return new Promise((resolve) => {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

        if (this.map) {
          this.map.remove();

          this.map = null;
        }

        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          zoom: 9,
          interactive: false,
        });

        this.map.on("load", () => {
          new mapboxgl.Marker()
            .setLngLat([this.site.lng, this.site.lat])
            .addTo(this.map);

          resolve();
        });
      });
    },
    toggleSpinner(e) {
      this.$parent.toggleSpinner(e);
    },
    async getSite() {
      this.toggleSpinner(true);

      this.site = null;

      this.API.get(`sites/${this.$route.params.id}`)
        .then((retVal) => {
          this.site = retVal.data;

          if (this.site.lat && this.site.lng) {
            this.$nextTick(async () => {
              await this.initMap();

              this.map.setCenter([this.site.lng, this.site.lat]);
            });
          }

          this.toggleSpinner(false);

          return;
        })
        .catch(() => {
          this.toggleSpinner(false);

          return;
        });
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();

      this.map = null;
    }
  },
};
</script>